@tailwind base;
@tailwind utilities;
@tailwind components;

* {
    font-family: 'Satoshi', sans-serif;
}

body {
    @apply bg-gray-50 min-h-screen;
}